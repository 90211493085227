import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import PicStudioLogo from '@components/PicStudioLogo/PicStudioLogo';
import { Button } from '@components/ui/button';
import { useRouter } from 'next/router';

export default function NotFound() {
  // List of images
  const images = [
    '/images/nerdy.webp',
    '/images/nerdy1.webp'
    // '/images/nerdy2.webp',
    // '/images/nerdy3.webp'
    // ...
  ];

  // Select a random image from the list
  const randomImage = images[Math.floor(Math.random() * images.length)] as string;

  const router = useRouter();

  const goHome = () => {
    router.replace('/');
  };

  return (
    <>
      <div className="grid min-h-screen grid-cols-1 grid-rows-[1fr,auto,1fr] bg-muted lg:grid-cols-[max(50%,36rem),1fr]">
        <header className="mx-auto w-full max-w-7xl px-6 pt-6 sm:pt-10 lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:px-8">
          <Link href="/">
            <PicStudioLogo />
          </Link>
        </header>
        <main className="mx-auto w-full max-w-7xl px-6 py-24 sm:py-32 lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:px-8">
          <div className="max-w-lg">
            <div className="block lg:hidden p-3 pb-8 bg-studio-200 dark:bg-white w-fit mb-6 rounded-sm -rotate-2">
              <div className="aspect-square overflow-hidden w-32 h-32 rounded-sm">
                <Image src={randomImage} alt="" width={3000} height={3000} className="" />
              </div>
            </div>
            <p className="text-base font-semibold leading-8 text-accent">404</p>
            <h1 className="mt-4 text-3xl font-bold tracking-tight text-primary sm:text-5xl">Geez, I&apos;m sorry.</h1>
            <p className="mt-6 text-base leading-7 text-muted-foreground">
              There was a mistake, and we couldn&apos;t find that page.
            </p>
            <div className="mt-10">
              <Button onClick={goHome} variant={'link'}>
                <span aria-hidden="true">&larr;</span> Back to home
              </Button>
            </div>
          </div>
        </main>
        <footer className="self-end lg:col-span-2 lg:col-start-1 lg:row-start-3">
          <div className="bg-muted border-t border-muted py-10">
            <nav className="mx-auto flex w-full max-w-7xl items-center gap-x-4 px-6 text-sm leading-7 text-muted-foreground lg:px-8">
              <Link href="https://twitter.com/picstudioai" target="_blank">
                Twitter
              </Link>
            </nav>
          </div>
        </footer>
        <div className="hidden lg:relative lg:col-start-2 lg:row-start-1 lg:row-end-4 lg:block bg-black">
          <Image
            src={randomImage}
            alt=""
            width={3000}
            height={3000}
            className="absolute inset-0 h-full w-full object-cover opacity-50"
          />
        </div>
      </div>
    </>
  );
}
